







import { Component, Emit, Vue } from 'vue-property-decorator';
import { WPPost, CourseResponseCourses, CourseResponse } from '../../../../types/typescript-axios/api';
import VueRouter, {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Error from '@/components/contents/Error.vue';
import store from '@/store';
import {isReservedStatus, isReservedStatus2, isReservedStatus3, courseTimeStatus} from '@/plugins/helper';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getContentsData(url: string, params: any = {}, headers: any = {}) {
  const res = await new Promise((resolve, reject) => {
    axios.get<CourseResponse>(url, {
      params,
      headers,
    })
    .then( (response) => {
      resolve(response);
    })
    .catch( (e: AxiosError) => {
      reject(e);
    });
  });
  return res;
}

async function getDatas(to: Route) {
  let status: number = 200;
  const $to: any = to;
  const paramsName: string = $to.params.courceId;
  let contentsData: CourseResponseCourses;
  if ($to.query.preview) {
    const params = {
      status: 'auto-draft',
    };
    const headers = { 'X-WP-Nonce': window.WP_API_Settings.nonce };

    try {
      const res: any = await getContentsData(`/wp-json/moc/course/${paramsName}/revisions`, params, headers);
      if (res.data.courses.length > 0) {
        contentsData = res.data.courses[0];
      } else {
        return {
          status: 404,
        };
      }
    } catch (e) {
      return {
        status: e.response.status,
      };
    }
  } else {
    try {
      const res = await axios.get<CourseResponse>(`/wp-json/moc/course/${paramsName}/detail`);
      contentsData = res.data.courses[0];
      status = res.status;
      if (Array.isArray(contentsData.data1)) {
        return {
          status: 500,
        };
      }
    } catch ( error: any ) {
      return {
        status: error.response.status,
      };
    }
  }
  return {
    status,
    contentsData,
    paramsName,
    $to,
  };
}

function validate(courseDetail: CourseResponseCourses): number {
  const now = new Date();
  const cancelConfirmDedline = courseDetail.data2.acf?.cancel_confirm_dedlin
    ? new Date(courseDetail.data2.acf?.cancel_confirm_dedlin)
    : new Date(now.getTime() + 60000);
  const status1: number = isReservedStatus(courseDetail);
  const status2: number = isReservedStatus2(courseDetail);
  const status3: number = isReservedStatus3(courseDetail);
  const timeStatus: number = courseTimeStatus(courseDetail);
  if (
    cancelConfirmDedline > now && // キャンセル確認期限が過ぎていない
    status1 === 2 && status2 === 2 && status3 === 0 && // ステータスがキャンセル待ち繰り上げ確認？
    timeStatus !== 3 // 終了していない
  ) {
    return 200;
  }
  return 404;
}

@Component({
  components: {
    Error,
  },
})
export default class CourseWaiting extends Vue {
  private title: any = '';
  private courseDetail: CourseResponseCourses|null = null;
  private etcData: WPPost[] = [];
  private status = 200;
  private paramsName: number = 0;

  private stepNavi: StepNaviData[] = [
    {
      title: '確認・申込み',
    },
    {
      title: '申込み完了',
    },
  ];

  @Emit()
  private setCourseDetail(value: any): void {
    this.courseDetail = value;
  }
  @Emit()
  private setTitle(value: any): void {
    this.title = value;
  }
  @Emit()
  private setEtcData(value: any): void {
    this.etcData = value;
  }

  private async mounted() {
    const url: string = '/wp-json/moc/course/';
    const data = await axios.get<AxiosResponse>(url, {
      params: {
        status: 'accepting',
        orderby: 'event_date',
        per_page: 9,
      },
    });
    const closedData = await axios.get<AxiosResponse>(url, {
      params: {
        status: 'closed',
        orderby: 'event_date',
        per_page: 9,
      },
    });
    const etcData: any = data.data;
    const closedEtcData: any = closedData.data;
    const newEtcData = etcData.courses.concat(closedEtcData.courses);
    const newData = newEtcData.filter((value: any, index: number) => {
      if (index < 9 && value.data2.slug !== this.paramsName) {
        return true;
      }
    });
    this.etcData = newData;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    if (data.status !== 200) {
      store.commit('window/setTitle', data.status + ' Error');
      next((vm: {status: number}) => {
        vm.status = 404;
      });
      return;
    }

    const course: CourseResponseCourses = data.contentsData;
    data.status = validate(data.contentsData);
    if (data.status !== 200) {
      store.commit('window/setTitle', data.status + ' Error');
      next((vm: {status: number}) => {
        vm.status = 404;
      });
      return;
    }

    const canAccess: AxiosResponse = await axios.get<AxiosResponse>(`/wp-json/moc/course/${course.data2.acf?.postgres_id}/waiting/${to.params.key}`)
    .catch((e) => {
      return e;
    });

    if (canAccess.status !== 200) {
      next((vm: {status: number}) => {
        vm.status = 404;
      });
      return;
    }

    store.commit('window/setTitle', course.data2.title.rendered);
    next((vm: {
      title: any,
      courseDetail: CourseResponseCourses,
      // etcData: WPPost,
      paramsName: number,
      status: number,
    }) => {
      vm.status = data.status;
      vm.courseDetail = data.contentsData;
      vm.title = course.data2.title.rendered;
      vm.paramsName = Number(data.$to.params.courceId);
    });
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    if (data.status !== 200) {
      store.commit('window/setTitle', data.status + ' Error');
      this.status = 404;
      return;
    }

    const course: CourseResponseCourses = data.contentsData;

    store.commit('window/setTitle', course.data2.title.rendered);
    this.status = data.status;
    this.courseDetail = data.contentsData;
    this.title = course.data2.title.rendered;
    this.paramsName = Number(data.$to.params.courceId);

    next();
  }
}
